.branding {
	margin: 20px;
}

#block-user-login {
	a {
		border: 1px solid black;
		background-color: $primary-color;
		color: white;
		padding: 5px;
		border-radius: 5px;
	}
	a:hover {
		background-color: $nav-color;
	}
}

@media only screen and (max-width: 500px) {
	.header__logo-image {
		margin: 10px;
		float: none;
	}

	.header__name-and-slogan {
		float: none;
		margin: 10px;
	}

	.header__logo {
		float: none;
	}

	header .branding {
		margin: 0px;
    	width: 100%;
    	float: none;
	}
}