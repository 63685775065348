h1 {
	font-size: 18px;
	color: $primary-color;
}



.layout-3col__full h1 {
	font-size: 2em;
	line-height: 1.2em;
	margin-bottom: 1rem;
}

a,
.layout-center .block-user a{
	text-decoration: none;
	color: #003353;
}

a:hover {
	color: firebrick;
}

.header__site-name a:hover {
	text-decoration: none;
}

@media only screen and (max-width: 600px) {
	p {
		font-size: 14px;
		line-height: 18px;
	}
	h1 {
		font-size: 16px;
		line-height: 18px;
	}
}

.page-node-317 {
	.layout-center {
		margin-top: 80px;
	}
	h1 {
		font-size: 1.51rem;
		line-height: 2.25rem;
		margin-top: 0px;
		margin-bottom: 0px;
	}
	#block-block-12 {
		margin-top: 0px;
	}
}
