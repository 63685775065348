input[type='submit'].sppx-button,
.sppx-button,
a.sppx-button {
	background-color: $nav-color;
}

input[type='submit'].sppx-button:hover,
.sppx-button:hover,
a.sppx-button:hover {
	background-color: lighten($nav-color, 30%);
	color: white;
}

