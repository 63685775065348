.view-partners-main-list {
	form table {
		max-width: 100%;
	}
	table {
		border: none;
	}
}

.views-field-field-orgs-logo {
	width: 25%;
}

// Percent funded

.progress .filled {
	background-color: green;
}

