body {
  //font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  //
  //.layout-center {
  //  background-color: #fff;
  //  max-width: none;
  //  padding: 0;
  //}
}

#copyright p {
	color: black;
}

h2 {
	color: $primary-color;
}

@media only screen and (max-width: 400px) {
	.front .block-quicktabs h2 {
		font-size: 1.3rem;
		text-align: center;
		line-height: 1.9rem;
	}
}

