header.banner {
  background-color: none;
  background-image: url("/sites/all/themes/portal/banner.jpg");
  background-position: center right;
}

.header__site-link:link, 
.header__site-link:visited,
.header__site-slogan {
  color: white;
}

.block-user a {
	color: white;
}

.header__logo-image {
  height: 100px;
}

.header__name-and-slogan {
  padding-top: 15px;
  text-shadow: 0 0 2px #000000;
}

body header {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media only screen and (max-width: 500px) {
  header.banner {
    background-position: center right;
  }
  body header {
    padding-top: 15px;
    padding-bottom: 0;
  }
}

header .branding {
  margin: 0px 20px;
}

.header__site-name {
  line-height: 2rem;
  margin-top: 0px;
}

@media only screen and (max-width: 500px) {
  header #header-content .branding {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
  .header__logo-image {
    height: 80px;
  }
  .branding .header__name-and-slogan {
    margin: 0px;
    float: none;
  }
  .branding .header__logo {
    margin: 0px;
  }
  header.banner .branding a {
    font-size: 22px;
  }
  header.banner .header__site-slogan {
    font-size: 14px;
  }
  header.banner .silicon-login {
    font-size: 10px;
  }
}

@media only screen and (max-width: 400px) {
  header #header-content .branding {
    width: 80%;
    margin: 0px 10%;
  }
  .header__logo-image {
    height: 80px;
  }
  .branding .header__name-and-slogan {
    margin: 0px;
    float: none;
  }
  .branding .header__logo {
    margin: 0px;
  }
  header.banner .branding a {
    font-size: 18px;
  }
  header.banner .header__site-slogan {
    font-size: 12px;
  }
}

