// Variables specific to Silicon Theme

$silicon-page-width:           1100px;

$border-radius: 4px;

// MN Theme Colors


// HEADER and FOOTER and PAGE HEADERS and BUTTONS
$primary-color           :   #00682D;
$primary-color-text      :   white;
$primary-color-hover     :   black;


// Main Nav Bar
$nav-color               :   #E0A300;
$sub-nav-color           :   #ffbb00;
$nav-links               :   white;
$nav-links-hover         :   pink;

// If different from secondary color
$page-headlines          :     $nav-color;
$page-links              :     $nav-color;
$page-links-hover        :     $primary-color;

// If different from primary color
$footer-background       :    $primary-color;
$footer-text             :    $primary-color-text;
$footer-text-hover       :    $primary-color-hover;


$table-background        :    #F1F1F1;
