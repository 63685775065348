.flag-outer {
  background-color: $primary-color;
  border-radius: $border-radius;
  padding: 4px;
  width: 160px;
  margin: 0px auto;
  text-align: center;
  font-size: 13px;
  line-height: 15px;
  color: white;
  a {
    color: white;
  }
}

.flag-outer:hover {
  background-color: $sub-nav-color;
  color: black;
  a {
    color: black;
  }
}