.header__logo {
  width: 100px;
}

#navbar {
  width: 100%;
}

header {
  background: #fff;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 1em 0; 

  #header-content {
    margin: 0px auto;
    max-width: $silicon-page-width;
    clear: both;
  }

  .branding {
    width: 60%;
    float: left;
  }

  .header__name-and-slogan {

  }
  .header__region {
    margin: 0px;
    padding: 0px;
  }
}





