.responsive-menus.responsified .responsive-menus-simple li {
  background: $nav-color;
  :hover {
    background-color: $sub-nav-color;
  }
}

.responsive-menus.responsified .responsive-menus-simple li a {
  padding: .4em 5%;
}

.responsive-menus.responsified .responsive-menus-simple li ul li a {
  padding: .4em 5% .4em 10%;
}

.block-superfish {
  width: 100%;
  margin: 0px auto;
}

#superfish-1 {
  max-width: $silicon-page-width;
  margin: 0px auto;
}

ul#superfish-1 {
  display: flex;
  padding-left: 0;
  li {
    display: inline;
    a,
    a:active {
      color: white;
      text-decoration: none;
    }

    a:hover {
      color: black;
    }

    a.active {
      color: #f15b61;
    }
    ul li {
      background-color: $sub-nav-color;
      a,
      a:active,
      a:visited {
        color: white;
      }
      a:hover {
        color: black;
      }
    }
  }
 
  li.first {
    a {
      border-left: 0;
      color: white;
    }
  }
}

.sf-accordion-toggle {
  display: none;
}

.responsive-menus.responsified span.toggler {
  padding: 10px 0px;
  border-radius: 0px;
  display: block;
  background-color: $nav-color;
  color: #fff;
  box-shadow: 2px 2px 2px #000;
  font-size: 1.35em;
  text-align: center;
  cursor: pointer;
  outline: none;
  li {
    background-color: $sub-nav-color;
  }
}

.responsive-menus.responsified .responsive-menus-simple li {
    background: $sub-nav-color;
}

#navbar {
  background-color: $nav-color;
}

#navbar li ul li a:active {
  padding: .75em;
}

