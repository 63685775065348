footer {
	background-color: $primary-color;
	h1, h2, p, a {
		color: $footer-text;
	}
	a:visited {
		color: darken($footer-text, 20%);
	}
	a:hover {
		color: $footer-text-hover;
	}
}

